import { createLoader } from "../../../../../../../js/react/components/general/notifications";
import axios from "axios";
import PropTypes from "prop-types";
import { useContext, useState, useCallback } from "react";
import { can, route } from "../../../../../../../js/helpers";
import { uctrans, trans } from "../../../../../../../js/lib/Translator";
import DeleteDialog from "../../../../../../../js/react/components/general/DeleteDialog";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import PageLibraryContext from "./PageLibraryContext";
import Tippy from "@tippyjs/react";
import { format } from "../../../../../../../js/lib/Date";

export default function PageLibraryItem({ page: page }) {
	const { selected, onSelectMarkClick, refreshPages } = useContext(PageLibraryContext);
	const [deleting, setDeleting] = useState(false);
	const [deleteData, setDeleteData] = useState({});

	const askDelete = useCallback(async () => {
		const result = await axios.get(route(`crm.cms.pages.delete`, page.id));
		if (result.status === 200) {
			setDeleteData(result.data);
			setDeleting(true);
		}
	}, [page.id]);

	const submitDelete = useCallback(async () => {
		setDeleting(false);
		const loader = createLoader(uctrans("general.:item_is_being_deleted", {}, { item: "cms.pages.singular" }));
		try {
			await axios.delete(route(`crm.cms.pages.destroy`, page.id));
			loader.success(uctrans("general.deleted_:item", {}, { item: "cms.pages.singular" }));
			refreshPages();
		} catch (error) {
			console.error(error);
			loader.failure(error.message);
		}
	}, [page.id, refreshPages]);

	return (
		<>
			<div className="overview-item cursor-pointer">
				<div className="overview-item-cell truncate w-2/24 cursor-pointer" onClick={() => onSelectMarkClick(page)}>
					{selected.indexOf(page.id) !== -1 ? (
						<EvaIcon fill="#009286" type="checkmark-square-2-outline" width="20" height="20" />
					) : (
						<EvaIcon fill="#cccccc" type="square-outline" width="20" height="20" />
					)}
				</div>
				<div className="overview-item-cell truncate w-2/24 ">
					{(function () {
						switch (page.publish_status) {
							case 1:
								return (
									<Tippy
										placement="right"
										arrow
										animation="perspective"
										duration="400"
										content={uctrans("cms.publish_statuses.1")}>
										<span>
											<EvaIcon type="checkmark-circle-2" fill="#6FAD2B" height="18" width="18" />
										</span>
									</Tippy>
								);
							case 2:
								return (
									<Tippy
										placement="right"
										arrow
										animation="perspective"
										duration="400"
										content={uctrans("cms.publish_statuses.2")}>
										<span>
											<EvaIcon type="close-circle" fill="#BF3030" height="18" width="18" />
										</span>
									</Tippy>
								);
							case 3:
								if (page.published_now) {
									return (
										<Tippy
											placement="right"
											arrow
											animation="perspective"
											duration="400"
											content={`${uctrans("cms.publish_statuses.3")} ${
												page.published_from ? format(page.published_from, "dd-MM-y") : "..."
											} - ${page.published_to ? format(page.published_to, "dd-MM-y") : "..."}`}>
											<span>
												<EvaIcon type="clock" fill="#34D0B6" height="18" width="18" />
											</span>
										</Tippy>
									);
								} else {
									return (
										<Tippy
											placement="right"
											arrow
											animation="perspective"
											duration="400"
											content={`${uctrans("cms.publish_statuses.3")} ${
												page.published_from ? format(page.published_from, "dd-MM-y") : "..."
											} - ${page.published_to ? format(page.published_to, "dd-MM-y") : "..."}`}>
											<span>
												<EvaIcon type="clock" fill="#FF9D40" height="18" width="18" />
											</span>
										</Tippy>
									);
								}
							default:
								return <EvaIcon type="question-mark-circle" fill="#c3c3c3" height="18" width="18" />;
						}
					})()}
				</div>
				<div className="overview-item-cell truncate w-2/24 ">
					{(function () {
						if (page.login_required) {
							return (
								<Tippy
									placement="right"
									arrow
									animation="perspective"
									duration="400"
									content={uctrans("cms.pages.fields.login_required")}>
									<span>
										<EvaIcon type="lock" fill="#9c9c9c" height="18" width="18" />
									</span>
								</Tippy>
							);
						}
					})()}
				</div>
				<div className="overview-item-cell truncate w-12/24">
					{can("cms.page", "update") ? (
						<a href={route("crm.cms.pages.edit", page)} className="row-title link">
							{page.title}
						</a>
					) : (
						page.title
					)}
				</div>
				<div className="overview-item-cell truncate w-6/24">
					<div className="overview-actions flex">
						{can("cms.page", "view") && (
							<a
								rel="noopener noreferrer"
								className="mr-2"
								href={route("crm.cms.pages.preview", page.id)}
								target="_blank">
								<Tippy
									placement="left"
									arrow
									animation="perspective"
									duration="400"
									content={uctrans("cms.pages.preview")}>
									<span>
										<EvaIcon type="navigation-2" fill="#249286" height="17" width="17" />
									</span>
								</Tippy>
							</a>
						)}
						<a className="mr-2" href={page.url} target="_blank" rel="noopener noreferrer">
							<Tippy placement="left" arrow animation="perspective" duration="400" content={page.url}>
								<span>
									<EvaIcon className="p-1 flex" type="external-link-outline" fill="#2B9286" height="17" width="17" />
								</span>
							</Tippy>
						</a>
						{can("cms.page", "create") && !page.only_one && (
							<a className="mr-2" href={route("crm.cms.pages.create-copy", page.id)}>
								<Tippy
									placement="left"
									arrow
									animation="perspective"
									duration="400"
									content={uctrans("general.add_:item", {
										item: trans("general.copy_of_:item", {}, { item: "cms.pages.singular" }),
									})}>
									<span>
										<EvaIcon className="p-1 flex" type="copy-outline" fill="#6cb2eb" height="17" width="17" />
									</span>
								</Tippy>
							</a>
						)}
						{can("cms.page", "delete") && (
							<a
								onClick={() => {
									askDelete();
								}}>
								<Tippy
									placement="left"
									arrow
									animation="perspective"
									duration="400"
									content={uctrans("general.delete")}>
									<span>
										<EvaIcon className="p-1 flex" type="trash-2-outline" fill="#f9acaa" height="17" width="17" />
									</span>
								</Tippy>
							</a>
						)}
					</div>
				</div>
			</div>
			{deleting && (
				<DeleteDialog
					onConfirm={() => submitDelete()}
					onCancel={() => setDeleting(false)}
					isOpen={deleting}
					deleteData={deleteData}
				/>
			)}
		</>
	);
}

PageLibraryItem.propTypes = {
	page: PropTypes.object.isRequired,
};
