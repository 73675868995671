import Tippy from "@tippyjs/react";
import map from "lodash/map";
import PropTypes from "prop-types";
import { lazy, useState, Suspense } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import ClickToSelect from "../../../../../../js/react/components/general/ClickToSelect";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";
import Radio from "../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../js/react/components/general/form/radio/RadioGroup";
import Select from "../../../../../../js/react/components/general/Select";
import Spinner from "../../../../../../js/react/components/general/Spinner";
import Translate from "../../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";
import useInputHandler from "../../../../../../js/react/hooks/useInputHandler";
import { route } from "../../../../../../js/helpers";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import useSelectHandler from "../../../../../../js/react/hooks/useSelectHandler";
import CollapsibleContent from "../../general/CollapsibleContent";
import TriggerMomentFilters from "../automatedMessages/TriggerMomentFilters";
import { CmsTypesContext } from "../../cms/cms-types-context";
import CopyImageSelector from "../../media/selector/CopyImageSelector";

const TextEditor = lazy(() => import(/* webpackChunkName: "tinymce" */ "../../forms/textEditor/TextEditor"));

export default function NewsletterBlockForm({
	newsletter,
	newsletterBlock,
	onSuccess,
	onCancel,
	staticData,
	readOnly,
}) {
	const [formData, setFormData] = useState({ ...newsletterBlock });

	const inputHandler = useInputHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);
	const selectHandler = useSelectHandler(setFormData);

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	const handleSuccess = () => {
		onSuccess();
	};

	const handleCancel = () => {
		onCancel();
	};

	const submitUrl = () => {
		if (Number(newsletter.type) === Number(staticData.message_communication_types.NEWSLETTER)) {
			return formData.id === -1
				? route("crm.communication.newsletter-blocks.store", { newsletter: newsletter.id })
				: route("crm.communication.newsletter-blocks.update", {
						newsletter: newsletter.id,
						newsletter_block: formData.id,
				  });
		} else if (Number(newsletter.type) === Number(staticData.message_communication_types.AUTOMATED_MESSAGE)) {
			return formData.id === -1
				? route("crm.communication.automated-messages.blocks.store", { newsletter: newsletter.id })
				: route("crm.communication.automated-messages.blocks.update", {
						newsletter: newsletter.id,
						newsletter_block: formData.id,
				  });
		}
	};

	return (
		<div className="min-h-screen md:flex">
			<div className="flex-1 w-2/3 mr-6">
				<AjaxForm
					method={formData.id === -1 ? "POST" : "PUT"}
					submitUrl={submitUrl()}
					loaderText={uctrans("general.:item_is_being_saved", {}, { item: "communication.newsletter_blocks.singular" })}
					successText={uctrans("general.saved_:item", {}, { item: "communication.newsletter_blocks.singular" })}
					onSuccess={handleSuccess}
					data={{ ...formData }}>
					<CmsTypesContext.Provider value={staticData.cmsTypes}>
						<div className="form-container">
							<div className="form-full">
								<LabeledTextInput
									name="title"
									value={formData.title ? `${formData.title}` : ""}
									onChange={inputHandler}
									label="communication.newsletter_blocks.fields.title"
									disabled={readOnly}
								/>
							</div>
						</div>

						<div className="form-container">
							<div className="form-full">
								<label htmlFor="content">{uctrans("communication.newsletter_blocks.fields.content")}</label>
								<Suspense
									fallback={
										<div style={{ width: 32, height: 32 }}>
											<Spinner />
										</div>
									}>
									<TextEditor
										type="mail"
										value={formData.content ? `${formData.content}` : ""}
										onChange={value => {
											genericHandler(value, "content");
										}}
										disabled={readOnly}
									/>
								</Suspense>
							</div>
						</div>

						<div className="form-container">
							<div className="form-1-2">
								<Checkmark
									name="without_borders"
									onChange={e => genericHandler(e.target.checked ? 1 : 0, "without_borders")}
									label={uctrans("communication.newsletter_blocks.fields.without_borders")}
									checked={!!formData.without_borders}
									disabled={readOnly}
								/>
							</div>
						</div>

						<div className="form-container">
							<div className="form-1-2">
								<label htmlFor="type">{uctrans("marketing.themes.singular")}</label>
								<Select
									value={staticData.marketing_themes.filter(
										({ value }) =>
											Number(value) === Number(formData.marketing_theme_id ? formData.marketing_theme_id : null),
									)}
									options={staticData.marketing_themes}
									name="marketing_theme_id"
									onChange={(value, metadata) => handleFormValueChange(metadata.name, value ? value.value : null)}
									disabled={readOnly}
								/>
							</div>
						</div>

						<div className="form-container">
							<div className="form-1-3">
								<label htmlFor="use_link">
									<Translate content="communication.newsletter_blocks.fields.use_link" />
								</label>
								<RadioGroup
									name="use_link"
									value={formData.use_link ? formData.use_link : 0}
									onChange={value => genericHandler(value, "use_link")}>
									<Radio label={uctrans("general.no")} value={0} disabled={readOnly} />
									<Radio label={uctrans("general.yes")} value={1} disabled={readOnly} />
								</RadioGroup>
							</div>
							{!!formData.use_link && (
								<div className="form-container">
									<div>
										<div className="form-1-3">
											<LabeledTextInput
												name="link_text"
												value={formData.link_text ? `${formData.link_text}` : ""}
												onChange={inputHandler}
												label="communication.newsletter_blocks.fields.link_text"
												disabled={readOnly}
											/>
										</div>
										<div className="form-1-3">
											<LabeledTextInput
												name="link_url"
												value={formData.link_url ? `${formData.link_url}` : ""}
												onChange={inputHandler}
												label="communication.newsletter_blocks.fields.link_url"
												required
												disabled={readOnly}
											/>
										</div>
									</div>
									<div className="form-1-2 italic text-xs">
										{uctrans("communication.newsletter_blocks.use_link_description")}
									</div>
								</div>
							)}
						</div>
						<div className="form-container">
							<div className="form-1-2">
								<label htmlFor="starts_new_section">
									<Translate content="communication.newsletter_blocks.fields.starts_new_section" />
								</label>
								<RadioGroup
									name="starts_new_section"
									value={formData.starts_new_section ? 1 : 0}
									onChange={value => genericHandler(value, "starts_new_section")}>
									<Radio label={uctrans("general.yes")} value={1} disabled={readOnly} />
									<Radio label={uctrans("general.no")} value={0} disabled={readOnly} />
								</RadioGroup>
								<div className="italic text-xs">
									{uctrans("communication.newsletter_blocks.starts_new_section_description")}
								</div>
							</div>
							{!formData.starts_new_section && (
								<div className="form-1-2">
									<div>
										<label htmlFor="is_column">
											<Translate content="communication.newsletter_blocks.fields.is_column" /> (
											<Translate content="communication.newsletter_blocks.fields.column_layout" />)
										</label>
										<RadioGroup
											name="is_column"
											value={formData.is_column ? formData.is_column : 0}
											onChange={value => genericHandler(value, "is_column")}>
											<Radio label={uctrans("general.no")} value={0} disabled={readOnly} />
											<Radio label={uctrans("general.yes")} value={1} disabled={readOnly} />
										</RadioGroup>
									</div>
									<div className="italic text-xs">
										{uctrans("communication.newsletter_blocks.is_column_description")}
									</div>
								</div>
							)}
						</div>

						<h4>{uctrans("communication.newsletter_blocks.layout_image")}</h4>

						<div className="form-container">
							<div className="form-1-3">
								<label htmlFor="type">{uctrans("communication.newsletter_blocks.fields.type")}</label>
								<Select
									value={staticData.newsletter_block_types.filter(
										({ value }) => Number(value) === Number(formData.type ? formData.type : 0),
									)}
									options={staticData.newsletter_block_types}
									name="type"
									onChange={selectHandler}
									isClearable={false}
									disabled={readOnly}
								/>
							</div>
							{!!formData.type && (
								<div className="form-2-3">
									<label htmlFor="type">{uctrans("communication.newsletter_blocks.fields.image")}</label>
									<CopyImageSelector
										imageKey="image"
										formData={formData}
										setFormData={setFormData}
										disabled={readOnly}
									/>
								</div>
							)}
						</div>
						{!!formData.type && (
							<div className="form-container">
								<div className="form-1-2">
									<label htmlFor="use_image_link">
										<Translate content="communication.newsletter_blocks.fields.use_image_link" />
									</label>
									<RadioGroup
										name="use_image_link"
										value={formData.use_image_link ? formData.use_image_link : 0}
										onChange={value => genericHandler(value, "use_image_link")}>
										<Radio label={uctrans("general.no")} value={0} disabled={readOnly} />
										<Radio label={uctrans("general.yes")} value={1} disabled={readOnly} />
									</RadioGroup>
								</div>
								{!!formData.use_image_link && (
									<div className="form-1-2">
										<LabeledTextInput
											name="image_link_url"
											value={formData.image_link_url ? `${formData.image_link_url}` : ""}
											onChange={inputHandler}
											label="communication.newsletter_blocks.fields.image_link_url"
											required
											disabled={readOnly}
										/>
									</div>
								)}
							</div>
						)}
						{Number(newsletter.type) === Number(staticData.message_communication_types.AUTOMATED_MESSAGE) ? (
							<div className="form-2-3">
								<label htmlFor="extra_filters">{uctrans("communication.triggers.fields.filters_to_see_block")}</label>
								<TriggerMomentFilters
									extraFilters={formData.extra_filters || []}
									onChange={filters => handleFormValueChange("extra_filters", filters)}
									triggerMomentTypes={staticData.trigger_moment_filter_types}
									organisations={staticData.organisations}
									teams={staticData.teams}
									relationManagers={staticData.relation_managers}
									segments={staticData.segments}
									activeCompanyOptions={staticData.active_company_options}
									actionPerformedByOptions={staticData.action_performed_by_options}
								/>
							</div>
						) : null}

						<div className="form-full">
							<div className="w-full md:w-1/2 lg:w-2/3 column">
								<SubmitBar
									item="communication.newsletter_blocks.singular"
									addAnotherAvailable={false}
									onCancel={handleCancel}
									submitDisabled={readOnly}
								/>
							</div>
						</div>
					</CmsTypesContext.Provider>
				</AjaxForm>
			</div>
			<div className="flex-none w-1/3 border-l pl-12">
				<h2>Tags</h2>
				{map(staticData.newsletter_tags, (tags, category) => {
					const notForClientTreatment =
						!newsletter.for_client_treatment && ["clienttreatment", "clienttreatment_landing_page"].includes(category);

					const notForQuestionnaire = !newsletter.questionnaire_id && category === "questionnaires";

					const isNewsLetter =
						Number(newsletter.type) !== Number(staticData.message_communication_types.AUTOMATED_MESSAGE);

					if (isNewsLetter && (notForClientTreatment || notForQuestionnaire)) {
						return null;
					} else {
						return (
							<CollapsibleContent key={category} title={uctrans(`communication.tags.categories.${category}`)}>
								<div className="text-xs">
									{map(tags, (tag, i) => (
										<div className="flex justify-between" key={i}>
											<ClickToSelect>{`{${tag.tag}}`}</ClickToSelect>
											<Tippy
												placement="right"
												arrow
												animation="perspective"
												duration="400"
												content={
													<p>
														{tag.description}
														<br />
														<strong>{uctrans("communication.tags.settings.fields.default")}:</strong>
														<br />
														{tag.is_html ? <span dangerouslySetInnerHTML={{ __html: tag.default }} /> : tag.default}
														<br />
														<strong>{uctrans("communication.tags.settings.fields.preview")}:</strong>
														<br />
														{tag.is_html ? <span dangerouslySetInnerHTML={{ __html: tag.preview }} /> : tag.preview}
													</p>
												}>
												<span>
													<EvaIcon type="info-outline" fill="#249286" height="18" width="18" />
												</span>
											</Tippy>
										</div>
									))}
								</div>
							</CollapsibleContent>
						);
					}
				})}

				<div className="form-container -mr-0 mt-8">
					<h6>{uctrans("communication.tags.default_values")}</h6>
					<p>{uctrans("communication.tags.default_values_description")}</p>
				</div>
			</div>
		</div>
	);
}
NewsletterBlockForm.propTypes = {
	newsletter: PropTypes.object.isRequired,
	newsletterBlock: PropTypes.object.isRequired,
	staticData: PropTypes.object.isRequired,
	onSuccess: PropTypes.func,
	onCancel: PropTypes.func,
	readOnly: PropTypes.bool,
};
