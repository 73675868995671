import PropTypes from "prop-types";
import map from "lodash/map";
import FormattedNumberText from "../../FormattedNumberText";

export default function FinancialBundledReport({ data }) {
	let colSpan = 2;
	if (data && data.extra_data) {
		if (data.extra_data.province) {
			colSpan++;
			if (data.extra_data.township) {
				colSpan++;
				if (data.extra_data.zipcode_area_from || data.extra_data.zipcode_area_until) {
					colSpan++;
				}
			}
		}
	}

	const postCodeLabel = () => {
		let postCodeLabel = "";

		if (data.extra_data.zipcode_area_from != null && data.extra_data.zipcode_area_until == null) {
			postCodeLabel = `Postcodes vanaf ${data.extra_data.zipcode_area_from.number}`;
		} else if (data.extra_data.zipcode_area_until != null && data.extra_data.zipcode_area_from == null) {
			postCodeLabel = `Postcodes t/m ${data.extra_data.zipcode_area_until.number}`;
		} else if (data.extra_data.zipcode_area_from != null && data.extra_data.zipcode_area_until != null) {
			if (data.extra_data.zipcode_area_from === data.extra_data.zipcode_area_until) {
				postCodeLabel = `Postcode ${data.extra_data.zipcode_area_from.number}`;
			} else {
				postCodeLabel = `Postcode ${data.extra_data.zipcode_area_from.number} t/m ${data.extra_data.zipcode_area_until.number}`;
			}
		}

		return postCodeLabel;
	};

	return (
		<table className="marktscan_rapportage_table rapportage_table reporttable">
			<thead>
				<tr>
					<th colSpan={colSpan}>{data.title ? data.title : ""}</th>
				</tr>
				<tr className="head2">
					<td>% van de huishoudens dat</td>
					{data.extra_data && (data.extra_data.zipcode_area_from || data.extra_data.zipcode_area_until) && (
						<td className="aantal groeptitel postcode">{postCodeLabel()}</td>
					)}
					{data.extra_data && data.extra_data.township && (
						<td className="aantal groeptitel gemeente">{data.extra_data.township.name}</td>
					)}
					{data.extra_data && data.extra_data.province && (
						<td className="aantal groeptitel provincie">Provincie {data.extra_data.province.name}</td>
					)}
					<td className="aantal groeptitel">Land</td>
				</tr>
			</thead>
			<tbody>
				{map(data.data, entry => (
					<tr>
						<td>{entry.label}</td>
						{data.extra_data &&
							(data.extra_data.zipcode_area_from != null || data.extra_data.zipcode_area_until != null ? (
								<td className="aantal percentage postcode">
									<FormattedNumberText
										value={
											entry.group && entry.group.zipcode && entry.group.zipcode.percentage
												? entry.group.zipcode.percentage
												: 0
										}
										decimalScale={1}
										percentage
									/>
								</td>
							) : null)}
						{data.extra_data && data.extra_data.township && (
							<td className="aantal percentage gemeente">
								<FormattedNumberText
									value={
										entry.group && entry.group.township && entry.group.township.percentage
											? entry.group.township.percentage
											: 0
									}
									decimalScale={1}
									percentage
								/>
							</td>
						)}
						{data.extra_data && data.extra_data.province && (
							<td className="aantal percentage provincie">
								<FormattedNumberText
									value={
										entry.group && entry.group.province && entry.group.province.percentage
											? entry.group.province.percentage
											: 0
									}
									decimalScale={1}
									percentage
								/>
							</td>
						)}

						<td className="aantal land">
							<FormattedNumberText
								value={
									entry.group && entry.group.country && entry.group.country.percentage
										? entry.group.country.percentage
										: 0
								}
								decimalScale={1}
								percentage
							/>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}

FinancialBundledReport.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
