import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import map from "lodash/map";
import { useState, useRef, useEffect } from "react";
import Nav from "../../../../../../js/lib/Nav";
import SingleSelectAutoComplete from "../../../../../../js/react/components/general/autocomplete/SingleSelectAutoComplete";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";
import CheckmarkGroup from "../../../../../../js/react/components/general/form/checkmark/CheckmarkGroup";
import Radio from "../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../js/react/components/general/form/radio/RadioGroup";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import Select from "../../../../../../js/react/components/general/Select";
import Spinner from "../../../../../../js/react/components/general/Spinner";
import Translate from "../../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";
import { route } from "../../../../../../js/helpers";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import useSelectHandler from "../../../../../../js/react/hooks/useSelectHandler";
import LoanDetailResults from "./components/LoanDetailResults";
import LoanResults from "./components/LoanResults";
import QualityMonitorResults from "./components/QualityMonitorResults";

export default function IntermediariesReport() {
	const [formValues, setFormValues] = useState(window.data.formValues ? cloneDeep(window.data.formValues) : []);
	const [staticData] = useState(window.data.staticData ? cloneDeep(window.data.staticData) : []);
	const [loading, setLoading] = useState(false);

	const formRef = useRef(null);
	const [result, setResult] = useState(null);
	const [activeCompany, setActiveCompany] = useState(window.data.active_company ?? null);
	const formInfo = window.data.formInfo ? cloneDeep(window.data.formInfo) : [];

	const genericHandler = useGenericHandler(setFormValues);
	const selectHandler = useSelectHandler(setFormValues);

	const onSuccess = response => {
		if (response.data) {
			setLoading(false);
			setResult(response.data);
		}
	};

	const onError = error => {
		console.error(error);
		setLoading(false);
	};

	const getComponent = result => {
		switch (result.type) {
			case "loans":
				return (
					<LoanResults
						staticData={staticData}
						loanStatuses={result.calculated.loan_statuses}
						data={result.calculated.table_data}
						title={result.title}
						perIntermediary={
							`${result.calculated.organisation_report_type}` ===
							`${staticData.organisation_report_types.EACH_INTERMEDIARY.key}`
						}
						chartData={{
							...result.calculated.chartData,
							title: result.title,
							chartOptions: result.calculated.chartOptions,
						}}
					/>
				);
			case "process":
			case "credit_risk":
				return <QualityMonitorResults data={result.calculated} title={result.title} qualityMonitorType={result.type} />;
			case "loantype":
			case "repaytype":
			case "interest_period":
			case "housebank_discount":
			case "national_loan_warranty":
				return (
					<LoanDetailResults
						tableNames={result.calculated.table_names}
						tableData={result.calculated.table_data}
						title={result.title}
						detailType={result.type}
						intermediaryChartData={result.calculated.chart_data_intermediary}
						ivChartData={result.calculated.chart_data_iv}
					/>
				);
			default:
				return null;
		}
	};

	const resetValues = () => {
		setActiveCompany(null);
		setResult(null);
	};

	const generatePdf = async () => {
		const result = await axios.post(route("crm.report.intermediaries.generate-pdf"), {
			...formRef.current.props.data,
			pdf: 1,
		});

		if (result && result.data.success) {
			Nav.go(
				route("crm.report.intermediaries.download-pdf", {
					download_key: result.data.download_key,
				}),
			);
		}
	};

	const fetchActiveCompany = async company => {
		setLoading(true);
		const result = await axios.get(route("crm.report.intermediaries.set-active-company", { company }));
		if (result && result.data) {
			setLoading(false);
			setActiveCompany(result.data);
			if (result.data.id) {
				setFormValues({
					...formValues,
					selected_company: result.data.id,
				});
			}
		}
	};

	useEffect(() => {
		if (activeCompany) {
			formRef.current && formRef.current.handleForm();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AjaxForm
			method="POST"
			submitUrl={route("crm.report.intermediaries.data")}
			onSubmit={() => setLoading(true)}
			onSuccess={onSuccess}
			onError={onError}
			data={{ ...formValues }}
			ref={formRef}
			useFlashMessage>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans("report.report")} - {uctrans("report.report_types.intermediaries_organisations")}
				</h1>
			</div>
			<div className="flex w-full justify-between">
				<div className="w-1/3 pr-2">
					<label htmlFor="user_id">
						<Translate content={`${uctrans("intermediaries.singular")}`} />
					</label>
					<SingleSelectAutoComplete
						name="selected_company"
						dataSource={route("crm.companies.search-active").toString()}
						item={formValues.selected_company}
						initialItem={activeCompany ? { label: activeCompany.name, value: activeCompany.id } : null}
						isClearable
						onChange={value => (value ? fetchActiveCompany(value.value) : resetValues())}
						placeholder={uctrans("general.type_to_select_:item", {
							item: `${trans("intermediaries.singular")} of ${trans("organisations.singular")}`,
						})}
						async
					/>
					{activeCompany && (
						<>
							<div className="flex w-full mt-5">
								<div className="w-1/2 pr-2">
									<label htmlFor="report_type">
										<Translate content="report.type_report" />
									</label>
									<Select
										isClearable={false}
										placeholder={uctrans("general.select_:item", {}, { item: "report.type_report" })}
										value={staticData.intermediaries_report_types.filter(
											({ value }) => `${value}` === `${formValues.report_type}`,
										)}
										options={formInfo.intermediariesReportTypes}
										name="report_type"
										onChange={selectHandler}
									/>
								</div>
								{formValues.report_type === "process" || formValues.report_type === "credit_risk" ? (
									<div className="w-1/2 pr-2">
										<label htmlFor="referenceDate">
											<Translate content="report.reference_date" />
										</label>
										<Select
											isClearable={false}
											placeholder={uctrans("general.select_:item", {}, { item: "report.reference_date" })}
											value={filter(
												formInfo.referenceDates,
												({ value }) => `${value}` === `${formValues.reference_date}`,
											)}
											options={formInfo.referenceDates}
											name="reference_date"
											onChange={selectHandler}
										/>
									</div>
								) : null}
								{formValues.report_type === "loans" ||
								formValues.report_type === "loantype" ||
								formValues.report_type === "repaytype" ||
								formValues.report_type === "interest_period" ||
								formValues.report_type === "housebank_discount" ||
								formValues.report_type === "national_loan_warranty" ? (
									<div className="w-1/2 pr-2">
										<label htmlFor="year">{uctrans("report.year")}</label>
										<Select
											name="year"
											value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.year}`)}
											placeholder={uctrans("general.select_:item", {}, { item: "report.year" })}
											options={formInfo.years}
											isClearable={false}
											onChange={selectHandler}
										/>
									</div>
								) : null}
							</div>
							{activeCompany &&
							`${activeCompany.type}` === `${staticData.company_types.ORGANISATION.key}` &&
							`${formValues.report_type}` === "loans" ? (
								<div className="flex w-full mt-5">
									<div className="w-1/2 pr-2">
										<label htmlFor="organisation_report_type">
											<Translate content="report.organisation_report_type" />
										</label>
										<RadioGroup
											name="organisation_report_type"
											value={formValues.organisation_report_type}
											onChange={value => genericHandler(value, "organisation_report_type")}>
											<Radio
												label={staticData.organisation_report_types.EACH_MONTH.label}
												value={staticData.organisation_report_types.EACH_MONTH.key}
											/>
											<Radio
												label={staticData.organisation_report_types.EACH_INTERMEDIARY.label}
												value={staticData.organisation_report_types.EACH_INTERMEDIARY.key}
											/>
										</RadioGroup>
									</div>
								</div>
							) : null}
							<div className="flex w-full justify-start">
								<button type="submit" disabled={loading} className="button button-primary leading-normal">
									{uctrans("report.show_report")}
								</button>
							</div>
						</>
					)}
				</div>
				{activeCompany && (
					<div className="flex w-1/2 justify-end">
						<div className="w-1/2 mt-2">
							<label htmlFor="pdf_parts">
								<Translate content="report.intermediaries.pdf_parts" />
							</label>
							<CheckmarkGroup
								name="pdf_parts"
								initialChecked={formValues.pdf_parts}
								onChange={pdfParts => genericHandler(pdfParts, "pdf_parts")}>
								{map(formInfo.checkBoxOptions, option => (
									<Checkmark key={option.value} value={option.value} name={option.value} label={option.label} />
								))}
							</CheckmarkGroup>

							<IconButton
								icon="file-text-outline"
								onClick={() => generatePdf()}
								content={uctrans("report.generate_total_report")}
								primary
							/>
						</div>
						<div className="w-1/2 ml-2">
							<label htmlFor="year_pdf">{uctrans("report.year")}</label>
							<Select
								name="year_pdf"
								placeholder={uctrans("general.select_:item", {}, { item: "report.year" })}
								value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.year_pdf}`)}
								options={formInfo.years}
								isClearable={false}
								onChange={selectHandler}
							/>

							<label htmlFor="reference_date_pdf">
								<Translate content="report.reference_date" />
							</label>
							<Select
								isClearable={false}
								placeholder={uctrans("general.select_:item", {}, { item: "report.reference_date" })}
								value={filter(
									formInfo.referenceDates,
									({ value }) => `${value}` === `${formValues.reference_date_pdf}`,
								)}
								options={formInfo.referenceDates}
								name="reference_date_pdf"
								onChange={selectHandler}
							/>
						</div>
					</div>
				)}
			</div>

			<div className="mt-5">
				{!!loading && (
					<div className="flex justify-center">
						<Spinner width={50} />
					</div>
				)}
				{result && !loading ? getComponent(result) : null}
			</div>
		</AjaxForm>
	);
}
