import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import map from "lodash/map";
import { useState, useEffect } from "react";
import { route } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import AutosizeTextarea from "../../../../../../js/react/components/general/form/AutosizeTextarea";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";
import CheckmarkGroup from "../../../../../../js/react/components/general/form/checkmark/CheckmarkGroup";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import Radio from "../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../js/react/components/general/form/radio/RadioGroup";
import Select from "../../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../../js/react/components/general/Translate";
import findSelectedOption from "../../../../../../js/react/hooks/findSelectedOption";
import FileInput from "../../forms/FileInput";
import TriggerMomentFilters from "../automatedMessages/TriggerMomentFilters";
import NewsletterBlockOverview from "../newsletters/NewsletterBlockOverview";
import Tippy from "@tippyjs/react";
import CopyImageSelector from "../../media/selector/CopyImageSelector";

export default function NewsletterFormFields({ newsletterFormData, newsletterStaticData, onChange, readonly }) {
	const [formData, setFormData] = useState(
		typeof newsletterFormData === "undefined" ? cloneDeep(newsletterFormData) : newsletterFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof newsletterStaticData === "undefined" ? cloneDeep(newsletterStaticData) : newsletterStaticData,
	);

	const [showQuestionnaires, setShowQuestionnaires] = useState(!!formData.questionnaire_id);

	useEffect(() => {
		if (typeof newsletterFormData !== "undefined") {
			setFormData(newsletterFormData);
		}
	}, [newsletterFormData]);

	useEffect(() => {
		if (typeof newsletterStaticData !== "undefined") {
			setStaticData(newsletterStaticData);
		}
	}, [newsletterStaticData]);

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
		onChange(newFormData);
	};

	const triggerMomentTypeOptions = staticData.trigger_type_options;
	const selectedTriggerMomentType = triggerMomentTypeOptions
		? findSelectedOption(triggerMomentTypeOptions, formData.trigger_type, false)
		: null;

	return (
		<>
			<div className="form-container">
				<div className="form-full">
					<label htmlFor="description">{uctrans("communication.newsletters.fields.description")}</label>
					<AutosizeTextarea
						name="description"
						value={formData.description}
						onChange={e => handleFormValueChange("description", e.target.value)}
						disabled={readonly}
					/>
				</div>
			</div>

			{Number(formData.type) === Number(staticData.message_communication_types.NEWSLETTER) ? (
				<div className="form-container">
					<div className="form-1-3">
						<label htmlFor="type">
							{uctrans("communication.newsletters.fields.subscription_type")} <span className="text-orange">*</span>
						</label>
						<Select
							value={findSelectedOption(staticData.subscription_type_options, formData.subscription_type_id)}
							options={staticData.subscription_type_options}
							name="subscription_type_id"
							onChange={(value, metadata) => handleFormValueChange(metadata.name, value.value)}
							isClearable={false}
							required
							disabled={readonly}
						/>
					</div>
					<div className="form-2-3">
						<label htmlFor="type">{uctrans("communication.newsletters.display")}</label>
						<div className="flex justify-between">
							<Checkmark
								name="mail_display"
								label={uctrans("communication.newsletters.fields.mail_display")}
								onChange={e => handleFormValueChange("mail_display", e.target.checked)}
								checked={!!formData.mail_display}
								disabled={readonly}
							/>
							<Checkmark
								name="site_display"
								label={uctrans("communication.newsletters.fields.site_display")}
								onChange={e => handleFormValueChange("site_display", e.target.checked)}
								checked={!!formData.site_display}
								disabled={readonly}
							/>
							<Checkmark
								name="app_display"
								label={uctrans("communication.newsletters.fields.app_display")}
								onChange={e => handleFormValueChange("app_display", e.target.checked)}
								checked={!!formData.app_display}
								disabled={readonly}
							/>
						</div>
					</div>
				</div>
			) : null}
			<div className="form-container">
				<div className="form-full">
					<LabeledTextInput
						name="subject"
						value={formData.subject ? `${formData.subject}` : ""}
						onChange={e => handleFormValueChange("subject", e.target.value)}
						label="communication.newsletters.fields.subject"
						required
						disabled={readonly}
					/>
				</div>
			</div>
			<div className="form-container">
				<div className="form-1-3">
					<label htmlFor="type">{uctrans("marketing.themes.singular")}</label>
					<Select
						value={
							staticData.marketing_themes &&
							staticData.marketing_themes.filter(
								({ value }) =>
									Number(value) === Number(formData.marketing_theme_id ? formData.marketing_theme_id : null),
							)
						}
						options={staticData.marketing_themes}
						name="marketing_theme_id"
						onChange={(value, metadata) => handleFormValueChange(metadata.name, value ? value.value : null)}
						disabled={readonly}
					/>
				</div>
			</div>
			{Number(formData.type) === Number(staticData.message_communication_types.AUTOMATED_MESSAGE) ? (
				<>
					<div className="form-container">
						<div className="form-2-3">
							<label htmlFor="sender_type">
								{uctrans("communication.newsletters.fields.sender_type")} <span className="text-orange">*</span>
							</label>
							<RadioGroup
								name="sender_type"
								value={formData.sender_type}
								onChange={value => handleFormValueChange("sender_type", value)}
								horizontal>
								{map(staticData.sender_types, type => (
									<Radio key={type.value} label={type.label} value={type.value} disabled={readonly} />
								))}
							</RadioGroup>
						</div>
						<div className="form-1-3">
							<label htmlFor="type">{uctrans("communication.newsletters.fields.subscription_type")}</label>
							<Select
								value={findSelectedOption(staticData.subscription_type_options, formData.subscription_type_id)}
								options={staticData.subscription_type_options}
								name="subscription_type_id"
								onChange={(value, metadata) => handleFormValueChange(metadata.name, value ? value.value : null)}
								placeholder={uctrans("communication.not_unsubscribable")}
								disabled={readonly}
							/>
						</div>
					</div>
					<div className="form-container">
						<div className="form-1-3">
							<label htmlFor="type">{uctrans("communication.newsletters.display")}</label>
							<div className="flex justify-between">
								<Checkmark
									name="site_display"
									label={uctrans("communication.newsletters.fields.site_display")}
									onChange={e => handleFormValueChange("site_display", e.target.checked)}
									checked={!!formData.site_display}
									disabled={readonly}
								/>
								<Checkmark
									name="app_display"
									label={uctrans("communication.newsletters.fields.app_display")}
									onChange={e => handleFormValueChange("app_display", e.target.checked)}
									checked={!!formData.app_display}
									disabled={readonly}
								/>
							</div>
						</div>
					</div>
					<div className="form-container">
						<div className="form-1-3">
							<label htmlFor="is_active">
								<Translate content="communication.automated_messages.fields.active" />
							</label>
							<RadioGroup
								name="is_active"
								value={formData.is_active}
								onChange={value => handleFormValueChange("is_active", value)}
								horizontal>
								<Radio label={uctrans("general.yes")} value={1} disabled={readonly} />
								<Radio label={uctrans("general.no")} value={0} disabled={readonly} />
							</RadioGroup>
						</div>
					</div>
				</>
			) : null}

			{Number(formData.type) === Number(staticData.message_communication_types.NEWSLETTER) ? (
				<>
					<div className="form-container">
						<div className="form-1-2">
							<Checkmark
								name="for_client_treatment"
								label={uctrans("communication.newsletters.fields.for_client_treatment")}
								onChange={e => handleFormValueChange("for_client_treatment", e.target.checked)}
								checked={!!formData.for_client_treatment}
								disabled={readonly}
							/>
						</div>
					</div>
					{formData.for_client_treatment && (
						<>
							<div className="form-container">
								<div className="form-1-2">
									<label htmlFor="type">
										{uctrans("client_treatment.singular")} <span className="text-orange">*</span>
									</label>
									<Select
										placeholder={uctrans("general.select_:item", {}, { item: "client_treatment.singular" })}
										value={
											staticData.client_treatments &&
											staticData.client_treatments.filter(
												({ value }) => Number(value) === Number(formData.client_treatment_id),
											)
										}
										options={staticData.client_treatments}
										name="client_treatment_id"
										onChange={(value, metadata) => handleFormValueChange(metadata.name, value ? value.value : "")}
										disabled={readonly}
									/>
								</div>
							</div>
							<div className="form-container">
								<div className="form-1-4">
									<div className="flex">
										<Checkmark
											name="client_treatment_invitation"
											label={uctrans("communication.newsletters.fields.client_treatment_invitation")}
											onChange={e => handleFormValueChange("client_treatment_invitation", e.target.checked)}
											checked={!!formData.client_treatment_invitation}
											disabled={readonly}
										/>
										<Tippy
											content={uctrans("client_treatment.fields.with_invitation")}
											placement="top"
											arrow
											animation="perspective">
											<span className="mt-1 ml-2">
												<EvaIcon type="info-outline" fill="#249286" height="18" width="18" />
											</span>
										</Tippy>
									</div>
								</div>
								<div className="form-1-4">
									<div className="flex">
										<Checkmark
											name="client_treatment_self_enrollment"
											label={uctrans("communication.newsletters.fields.client_treatment_self_enrollment")}
											onChange={e => handleFormValueChange("client_treatment_self_enrollment", e.target.checked)}
											checked={!!formData.client_treatment_self_enrollment}
											disabled={readonly}
										/>
										<Tippy
											content={uctrans("client_treatment.fields.with_self_invite")}
											placement="top"
											arrow
											animation="perspective">
											<span className="mt-1 ml-2">
												<EvaIcon type="info-outline" fill="#249286" height="18" width="18" />
											</span>
										</Tippy>
									</div>
								</div>
								<div className="form-1-4">
									<div className="flex">
										<Checkmark
											name="client_treatment_use_buttons"
											label={uctrans("communication.newsletters.fields.client_treatment_use_buttons")}
											onChange={e => handleFormValueChange("client_treatment_use_buttons", e.target.checked)}
											checked={!!formData.client_treatment_use_buttons}
											disabled={readonly}
										/>
										<Tippy
											content={uctrans("client_treatment.fields.with_buttons")}
											placement="top"
											arrow
											animation="perspective">
											<span className="mt-1 ml-2">
												<EvaIcon type="info-outline" fill="#249286" height="18" width="18" />
											</span>
										</Tippy>
									</div>
								</div>
								<div className="form-1-4">
									<div className="flex">
										<Checkmark
											name="client_treatment_presentation_attachment"
											label={uctrans("communication.newsletters.fields.client_treatment_presentation_attachment")}
											onChange={e =>
												handleFormValueChange("client_treatment_presentation_attachment", e.target.checked)
											}
											checked={!!formData.client_treatment_presentation_attachment}
											disabled={readonly}
										/>
										<Tippy
											content={uctrans("client_treatment.fields.with_presentation")}
											placement="top"
											arrow
											animation="perspective">
											<span className="mt-1 ml-2">
												<EvaIcon type="info-outline" fill="#249286" height="18" width="18" />
											</span>
										</Tippy>
									</div>
								</div>
							</div>
						</>
					)}

					<div className="form-container">
						<div className="form-1-2">
							<Checkmark
								name="for_questionnaire"
								label={uctrans("communication.newsletters.fields.for_questionnaire")}
								onChange={e => {
									const { checked } = e.target;
									if (checked === false) {
										handleFormValueChange("questionnaire_id", null);
									}
									setShowQuestionnaires(checked);
								}}
								checked={showQuestionnaires}
								disabled={readonly}
							/>
						</div>
					</div>
					{showQuestionnaires && (
						<div className="form-container">
							<div className="form-1-2">
								<label htmlFor="type">
									{uctrans("questionnaires.plural")} <span className="text-orange">*</span>
								</label>
								<Select
									placeholder={uctrans("general.select_:item", {}, { item: "questionnaires.singular" })}
									value={
										staticData.questionnaires &&
										staticData.questionnaires.filter(({ value }) => Number(value) === Number(formData.questionnaire_id))
									}
									options={staticData.questionnaires}
									name="questionnaire_id"
									onChange={(value, metadata) => handleFormValueChange(metadata.name, value ? value.value : "")}
									disabled={readonly}
								/>
							</div>
						</div>
					)}
				</>
			) : null}

			<h3>{uctrans("communication.newsletters.header")}</h3>
			<div className="form-full">
				<LabeledTextInput
					name="title"
					value={formData.title ? `${formData.title}` : ""}
					onChange={e => handleFormValueChange("title", e.target.value)}
					label="communication.newsletters.fields.title_top"
					required
					disabled={readonly}
				/>
			</div>
			<div className="form-full">
				<LabeledTextInput
					name="subtitle"
					value={formData.subtitle ? `${formData.subtitle}` : ""}
					onChange={e => handleFormValueChange("subtitle", e.target.value)}
					label="communication.newsletters.fields.subtitle_bottom"
					disabled={readonly}
				/>
			</div>
			<div className="form-container">
				<div className="form-1-2">
					<Checkmark
						name="use_header_image"
						label={uctrans("communication.newsletters.fields.use_header_image")}
						onChange={e => handleFormValueChange("use_header_image", e.target.checked)}
						checked={!!formData.use_header_image}
						disabled={readonly}
					/>
				</div>
				{formData.use_header_image ? (
					<div className="form-1-2">
						<label htmlFor="header_image">{uctrans("communication.newsletters.fields.header_image")}</label>
						<p className="text-xs italic">{uctrans("communication.newsletters.header_image_description")}</p>

						<CopyImageSelector
							imageKey="header_image"
							formData={formData}
							existingFileDownloadRoute="crm.communication.newsletters.download-header-image"
							setFormData={newFormData => {
								setFormData(newFormData);
								onChange(newFormData);
							}}
							forNewsletter
							disabled={readonly}
							asThumbnail
						/>
					</div>
				) : null}
			</div>
			{Number(formData.type) === Number(staticData.message_communication_types.NEWSLETTER) && (
				<div className="form-container">
					<div className="form-1-2">
						<LabeledTextInput
							name="cta_text"
							label="communication.newsletters.fields.cta_text"
							value={formData.cta_text ? `${formData.cta_text}` : ""}
							onChange={e => handleFormValueChange("cta_text", e.target.value)}
							disabled={readonly}
						/>
					</div>
					<div className="form-1-2">
						<LabeledTextInput
							name="cta_url"
							label="communication.newsletters.fields.cta_url"
							value={formData.cta_url ? `${formData.cta_url}` : ""}
							onChange={e => handleFormValueChange("cta_url", e.target.value)}
							disabled={readonly}
						/>
					</div>
				</div>
			)}

			<div>
				<h3>{uctrans("communication.newsletters.other")}</h3>
				<div className="form-container">
					<div className="form-1-2">
						<label htmlFor="type">{uctrans("communication.newsletters.fields.attachments")}</label>
						<FileInput
							initialFiles={formData.attachments}
							onChange={files => handleFormValueChange("attachments", files)}
							maxFiles={8}
							existingFileDownloadRoute="crm.communication.newsletters.download-attachment"
							disabled={readonly}
						/>
					</div>
					{Number(formData.type) === Number(staticData.message_communication_types.NEWSLETTER) ? (
						<div className="form-1-2">
							<label htmlFor="type">{uctrans("communication.newsletters.fields.app_image")}</label>
							<CopyImageSelector
								imageKey="app_image"
								formData={formData}
								setFormData={newFormData => {
									setFormData(newFormData);
									onChange(newFormData);
								}}
								disabled={readonly}
							/>
						</div>
					) : null}
				</div>
			</div>

			{Number(formData.type) === Number(staticData.message_communication_types.AUTOMATED_MESSAGE) ? (
				<>
					<h2>{uctrans("communication.triggers.fields.sending_moment")}</h2>
					<div className="form-full">
						<div className="form-1-2">
							<label htmlFor="type">
								{uctrans("communication.triggers.fields.type")} <span className="text-orange">*</span>
							</label>
							<Select
								value={selectedTriggerMomentType}
								options={triggerMomentTypeOptions}
								name="trigger_type"
								onChange={(value, metadata) => handleFormValueChange(metadata.name, value.value)}
								isClearable={false}
								required
								disabled={readonly}
							/>
						</div>
						{selectedTriggerMomentType && selectedTriggerMomentType.requiresAttribute ? (
							<div className="form-1-3">
								<LabeledTextInput
									required
									type="number"
									label="communication.triggers.fields.count"
									name="trigger_attribute"
									value={`${formData.trigger_attribute}`}
									onChange={e => handleFormValueChange(e.target.name, e.target.value)}
									disabled={readonly}
								/>
							</div>
						) : null}
					</div>

					{selectedTriggerMomentType && selectedTriggerMomentType.needsReceivers ? (
						<div className="form-full">
							<label htmlFor="type">
								{uctrans("communication.triggers.fields.receiver_types")} <span className="text-orange">*</span>
							</label>
							<CheckmarkGroup
								name="receivers"
								initialChecked={formData.receivers}
								onChange={roles => handleFormValueChange("receivers", roles)}>
								{staticData.trigger_moment_receivers.map(receiver => (
									<Checkmark
										key={receiver.receiver_type}
										value={receiver.id}
										name={receiver.receiver_type}
										label={receiver.title}
									/>
								))}
							</CheckmarkGroup>
						</div>
					) : null}
					<div className="form-2-3">
						<label htmlFor="extra_filters">{uctrans("communication.triggers.fields.filters_to_receive_email")}</label>
						<TriggerMomentFilters
							extraFilters={formData.extra_filters || []}
							onChange={filters => handleFormValueChange("extra_filters", filters)}
							triggerMomentTypes={staticData.trigger_moment_filter_types}
							organisations={staticData.organisations}
							teams={staticData.teams}
							relationManagers={staticData.relation_managers}
							segments={staticData.segments}
							activeCompanyOptions={staticData.active_company_options}
							actionPerformedByOptions={staticData.action_performed_by_options}
						/>
					</div>
				</>
			) : null}

			<div className="form-full">
				<div className="row">
					<div className="w-full md:w-1/2 lg:w-1/3 column">
						<SubmitBar
							translateLabel
							isCopy={staticData.is_copy}
							item={
								Number(formData.type) === Number(staticData.message_communication_types.NEWSLETTER)
									? "communication.newsletters.newsletter_info"
									: "communication.automated_messages.singular"
							}
							addAnotherAvailable={false}
							submitDisabled={staticData.readonly}
						/>
					</div>
				</div>
			</div>

			{!staticData.creating && (
				<div className="form-full">
					<NewsletterBlockOverview
						newsletter={formData}
						staticData={staticData}
						indexRoute={
							Number(formData.type) === Number(staticData.message_communication_types.NEWSLETTER)
								? route("crm.communication.newsletter-blocks.index", formData.id)
								: route("crm.communication.automated-messages.blocks.index", formData.id)
						}
						readOnly={staticData.readonly}
					/>
				</div>
			)}
		</>
	);
}

NewsletterFormFields.propTypes = {
	newsletterFormData: PropTypes.object,
	newsletterStaticData: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	readonly: PropTypes.bool,
};
