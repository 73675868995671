import { cloneDeep } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import SingleSelectAutoComplete from "../../../../../../js/react/components/general/autocomplete/SingleSelectAutoComplete";
import ConfirmDialog from "../../../../../../js/react/components/general/ConfirmDialog";
import { can, route } from "../../../../../../js/helpers";
import Tippy from "@tippyjs/react";
import { error, notify } from "../../../../../../js/react/components/general/notifications";
import Translate from "../../../../../../js/react/components/general/Translate";
import CompanyFormContext from "../../company/CompanyFormContext";
import StatutoryFields from "./StatutoryFields";
import axios from "axios";
import classNames from "classnames";

export default function StatutoryCompanyForm() {
	const {
		handleCompanyValueChange,
		company,
		handleCompanyFormValueChange,
		originalMainCompany,
		companyStatutoryBranches,
	} = useContext(CompanyFormContext);

	const [currentStatutoryCompany, setCurrentStatutoryCompany] = useState(
		typeof company !== "undefined" ? cloneDeep(company.statutory_company) : null,
	);
	const [originalStatutoryCompany] = useState(company.statutory_company ? company.statutory_company : null);
	const [hasStatutoryCompany, setHasStatutoryCompany] = useState(company.statutory_company_id !== null);
	const [openConnectStatutoryCompanyDialog, setOpenConnectStatutoryCompanyDialog] = useState(false);
	const [openChangeStatutoryCompanyDialog, setOpenChangeStatutoryCompanyDialog] = useState(false);
	const [openWarningChangeStatutoryCompanyDialog, setOpenWarningChangeStatutoryCompanyDialog] = useState(false);
	const [existingStatutoryCompany, setExistingStatutoryCompany] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleStatutoryCompanyChange = async (key, value) => {
		const newStatutoryCompany = { ...currentStatutoryCompany };
		newStatutoryCompany[key] = value;
		setCurrentStatutoryCompany(newStatutoryCompany);
		handleCompanyValueChange({ ...company, statutory_company: newStatutoryCompany });
	};

	const handleCurrentStatutoryCompanyChange = (statutoryCompany, newConnection) => {
		if (statutoryCompany === null && newConnection) {
			setCurrentStatutoryCompany(null);
		} else {
			handleCompanyFormValueChange("statutory_company_id", statutoryCompany.value);
			handleCompanyValueChange({ ...company });
			setCurrentStatutoryCompany({
				...statutoryCompany,
				new_connection: newConnection,
				id: statutoryCompany.value,
			});
		}
	};

	const handleStatutoryCompanyDialogConfirm = () => {
		const newCompanyData = { ...company };
		newCompanyData.statutory_company = currentStatutoryCompany;
		handleCompanyValueChange({
			...newCompanyData,
		});
		setHasStatutoryCompany(true);
	};

	const handleOpenChangeStatutoryCompanyDialog = useCallback(async () => {
		if (originalMainCompany) {
			try {
				await axios.get(route("crm.intermediaries.check-agenda-items", { id: company.id }));
				error(uctrans("companies.errors.would_leave_orphaned_agenda_items"));
			} catch (error) {
				if (error.response && error.response.status === 404) {
					setOpenWarningChangeStatutoryCompanyDialog(true);

					return;
				}

				throw error;
			}
		} else {
			setOpenChangeStatutoryCompanyDialog(true);
		}
	}, [company, originalMainCompany]);

	const revertToOriginalStatutoryCompany = () => {
		setCurrentStatutoryCompany(originalStatutoryCompany);
	};

	const searchKvkNumber = useCallback(async () => {
		try {
			setLoading(true);

			const response = await axios.get(
				route(`crm.statutory-companies.search-existing`, { kvk_number: `${currentStatutoryCompany.kvk_number}` }),
			);

			if (response.data && response.data.existing_statutory_company) {
				setExistingStatutoryCompany(response.data.existing_statutory_company);
			} else {
				setLoading(false);
				setExistingStatutoryCompany(null);
			}
		} catch (error) {
			notify("error", error, null);
			setLoading(false);
		}
	}, [currentStatutoryCompany]);

	const canSwitchStatutoryCompany = useMemo(() => {
		if (!originalMainCompany) {
			// We are a "nevenvestiging" and we can always switch
			return true;
		}

		if (companyStatutoryBranches === 1) {
			// We are the only vestiging of this statutory and we can switch
			return true;
		}

		return false;
	}, [companyStatutoryBranches, originalMainCompany]);

	useEffect(() => {
		if (
			currentStatutoryCompany &&
			currentStatutoryCompany.id === -1 &&
			currentStatutoryCompany.kvk_number.length === 8
		) {
			searchKvkNumber();
		}
	}, [currentStatutoryCompany, searchKvkNumber]);

	const dialogChildrenFields = () => (
		<>
			<div className="form-full">
				<div className="form-1-2">
					<label>
						<Translate content="companies.statutory.search_statutory_company" />
					</label>
					<SingleSelectAutoComplete
						name="statutory_company_id"
						dataSource={route("crm.statutory-companies.search").toString()}
						item={company.statutory_company_id}
						onChange={value => handleCurrentStatutoryCompanyChange(value ? value : null, true)}
						placeholder={uctrans("companies.statutory.search_explanation")}
						exclude={company.statutory_company_id ? [company.statutory_company_id] : []}
						async
						isClearable
						clearOnSelect
						disabled={loading}
					/>
				</div>
				<div className="form-1-2">
					<label>
						<Translate content="companies.statutory.did_not_found_statutory_company" />
					</label>
					<a
						className="link"
						onClick={() => {
							setCurrentStatutoryCompany({
								id: -1,
								name: "",
								kvk_number: "",
								afm_number: "",
							});
						}}>
						{uctrans("companies.statutory.create_new_statutory_company")}
					</a>
				</div>
			</div>
			{currentStatutoryCompany && (
				<div className="form-full">
					<StatutoryFields
						prefix="dialog"
						disabled={currentStatutoryCompany.id !== -1}
						statutoryCompany={currentStatutoryCompany}
						handleStatutoryCompanyChange={handleStatutoryCompanyChange}
					/>
				</div>
			)}
			{existingStatutoryCompany && (
				<div className="form-full">
					<span className="text-red">
						{uctrans("companies.statutory.kvk_number_already_in_use_by_:statutory_company", {
							statutory_company: existingStatutoryCompany.name,
						})}
					</span>
					<br />
					<a
						className="button button-tertiary"
						onClick={() => {
							setCurrentStatutoryCompany(existingStatutoryCompany);
							setExistingStatutoryCompany(null);
							setLoading(false);
						}}>
						{uctrans("companies.statutory.connect_to_existing_company")}
					</a>
				</div>
			)}
		</>
	);

	const changeFromStatutoryCompanyButton = useMemo(
		() => (
			<button
				className={classNames("button button-tertiary", {
					disabled: !canSwitchStatutoryCompany,
				})}
				onClick={event => {
					event.preventDefault();
					handleOpenChangeStatutoryCompanyDialog();
				}}
				disabled={!canSwitchStatutoryCompany}>
				{uctrans("companies.statutory.change_from_statutory_company")}
			</button>
		),
		[canSwitchStatutoryCompany, handleOpenChangeStatutoryCompanyDialog],
	);

	return (
		<>
			{!hasStatutoryCompany ? (
				<>
					{uctrans("companies.statutory.no_statutory_company_explanation")} <br />{" "}
					<a className="button button-primary" onClick={() => setOpenConnectStatutoryCompanyDialog(true)}>
						{uctrans("companies.statutory.connect_with_statutory_company")}
					</a>
				</>
			) : (
				<div className="form-full">
					{currentStatutoryCompany ? (
						<>
							<StatutoryFields
								disabled={currentStatutoryCompany.id === -1 ? false : hasStatutoryCompany && !originalMainCompany}
								statutoryCompany={currentStatutoryCompany}
								handleStatutoryCompanyChange={handleStatutoryCompanyChange}
								readOnly={!can("intermediary", "update_administrative")}
							/>
							{currentStatutoryCompany.id === -1 ? (
								<>
									<span className="w-1/2 text-sm font-bold">
										* {uctrans("companies.statutory.new_statutory_company_after_being_saved")}
									</span>
									<br />
								</>
							) : (
								<Tippy
									arrow
									animation="perspective"
									duration="400"
									disabled={canSwitchStatutoryCompany}
									content={
										companyStatutoryBranches > 1
											? uctrans("companies.statutory.only_change_when_main_company_has_one")
											: uctrans("companies.statutory.only_change_when_non_main_company")
									}>
									{/** work around for https://github.com/atomiks/tippyjs-react/issues/123 */}
									{canSwitchStatutoryCompany ? (
										changeFromStatutoryCompanyButton
									) : (
										<span>{changeFromStatutoryCompanyButton}</span>
									)}
								</Tippy>
							)}
						</>
					) : (
						originalStatutoryCompany && (
							<StatutoryFields
								disabled={hasStatutoryCompany && !originalMainCompany}
								statutoryCompany={originalStatutoryCompany}
								handleStatutoryCompanyChange={handleStatutoryCompanyChange}
							/>
						)
					)}
				</div>
			)}

			<ConfirmDialog
				width={1000}
				isOpen={openChangeStatutoryCompanyDialog}
				onCancel={() => {
					setOpenChangeStatutoryCompanyDialog(false);
					setOpenWarningChangeStatutoryCompanyDialog(false);
					revertToOriginalStatutoryCompany();
				}}
				confirmButtonLabel={uctrans("general.confirm")}
				cancelButtonLabel={uctrans("general.cancel")}
				title={uctrans("companies.statutory.change_from_statutory_company")}
				confirmIsDisabled={loading || currentStatutoryCompany === null}
				onConfirm={() => {
					handleStatutoryCompanyDialogConfirm();
					setOpenConnectStatutoryCompanyDialog(false);
					setOpenChangeStatutoryCompanyDialog(false);
				}}>
				<div className="form-full">{dialogChildrenFields()}</div>
			</ConfirmDialog>

			<ConfirmDialog
				width={1000}
				isOpen={openConnectStatutoryCompanyDialog}
				onCancel={() => {
					setOpenConnectStatutoryCompanyDialog(false);
					if (currentStatutoryCompany && currentStatutoryCompany.id === -1) {
						setCurrentStatutoryCompany(null);
					}
					setOpenChangeStatutoryCompanyDialog(false);
				}}
				confirmButtonLabel={uctrans("general.confirm")}
				cancelButtonLabel={uctrans("general.cancel")}
				title={uctrans("companies.statutory.manage_statutory_company")}
				confirmIsDisabled={loading || currentStatutoryCompany === null}
				onConfirm={() => {
					handleStatutoryCompanyDialogConfirm();
					setOpenConnectStatutoryCompanyDialog(false);
				}}>
				<div className="form-container mt-8 mb-0">
					{!hasStatutoryCompany ? (
						dialogChildrenFields()
					) : (
						<StatutoryFields
							prefix="dialog"
							statutoryCompany={currentStatutoryCompany}
							handleStatutoryCompanyChange={handleStatutoryCompanyChange}
						/>
					)}
				</div>
			</ConfirmDialog>

			<ConfirmDialog
				isOpen={openWarningChangeStatutoryCompanyDialog}
				onConfirm={() => {
					setOpenChangeStatutoryCompanyDialog(true);
					setOpenWarningChangeStatutoryCompanyDialog(false);
					setCurrentStatutoryCompany(null);
				}}
				onCancel={() => {
					setOpenChangeStatutoryCompanyDialog(false);
					setOpenWarningChangeStatutoryCompanyDialog(false);
				}}
				title={uctrans("general.are_you_sure")}
				confirmButtonLabel={uctrans("general.yes")}
				cancelButtonLabel={uctrans("general.no")}>
				<Translate content="companies.statutory.change_warning_description" />
			</ConfirmDialog>
		</>
	);
}
